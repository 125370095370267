import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Performance = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>browser performance (mini project #3)</h2>
        <p>For this mini project, think of the browser’s role in a performance that involved movement and/or sound. This can either be interpreted as (1) a browser that is performed or (2) one that performs; (1) would likely be more interactive, while (2) might be rather choreographed.</p>
        <p>Make use of the techniques reviewed in Lab #3 (CSS Animation, Web Audio API) and explore them further.</p>

        <p>Focus on an intentional design language and deliberate usage of techniques.</p>
        <p>Pay attention to how your website looks on different browser and screen sizes.</p>

        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy</p>
        <p>2. find it on your GitHub Pages (I mean, the rendered version)</p>
        <p>3. and link the project to your Room</p>
      </MiniLayout>
    </Layout>
  )
}



export default Performance